const DATA = {
  hero: {
    comingDate: "27 APR 2023 ",
    comingPlace: "Cyprus",
    comingTitle:
      "What’s on the Horizon for&nbsp;Global markets and how&nbsp;could it impact your&nbsp;portfolio?",
    comingDesc:
      "The EXANTE Horizon series is heading to Cyprus. Join us in Limassol, where our global panel of financial experts will discuss the key Q1 market movements and consider the investment opportunities and risks facing the markets in 2023 and beyond.",
    passedLink:
      "Watch our previous event<br>in <strong>London on 26 Jan 2023</strong>",
  },
  speakers: [
    {
      image:
        "https://exantestatic.s3.eu-west-1.amazonaws.com/participants/Arnab2x.jpg",
      imagex2:
        "https://exantestatic.s3.eu-west-1.amazonaws.com/participants/Arnab2x.jpg",
      position: "Head of Global Strategy EMEA, Invesco",
      name: "Arnab Das, PhD",
      text: "Arnab joined Invesco in 2015, leading EM macro research for Fixed Income. With a career in finance since 1992, he has held senior roles including Co-head of Economic Research at Roubini Global Economics and Global FX and EM Strategy leadership positions at Dresdner Kleinwort and JP Morgan.",
      showText: false,
    },
    {
      image:
        "https://exantestatic.s3.eu-west-1.amazonaws.com/participants/jaroslav-3.png",
      imagex2:
        "https://exantestatic.s3.eu-west-1.amazonaws.com/participants/jaroslav-3.png",
      position: "Vice President of the Management Board, NDM SA",
      name: "Jarosław Jamka, PhD",
      text: "Jarosław, a licensed investment advisor and broker, has over 30 years of experience in global macro and multi-asset strategies. He has held leadership roles at ING PTE, Ipopema TFI, and Quercus TFI, managing Poland’s largest pension fund and advising on billions in AUM.",
      showText: false,
    },
    {
      image:
        "https://exantestatic.s3.eu-west-1.amazonaws.com/participants/grzegorz.png",
      imagex2:
        "https://exantestatic.s3.eu-west-1.amazonaws.com/participants/grzegorz.png",
      position: "Former Finance Minister and Deputy Prime Minister",
      name: "Professor Grzegorz W. Kołodko",
      text: "A globally cited Polish economist, Grzegorz is the Director of TIGER at Kozminski University. His recent works include Global Consequences of Russia's Invasion of Ukraine (2023) and Political Economy of New Pragmatism (2022). He focuses on global transformation and economics",
      showText: false,
    },
    {
      image:
        "https://exantestatic.s3.eu-west-1.amazonaws.com/participants/Andrew_Kenningham-480x480.png",
      imagex2:
        "https://exantestatic.s3.eu-west-1.amazonaws.com/participants/Andrew_Kenningham-480x480.png",
      position: "Chief Europe Economist, Capital Economics",
      name: "Andrew Kenningham",
      text: "Andrew has overall responsibility for running the European economics service at Capital Economics and contributes to their international economic analysis. Andrew joined Capital Economics in 2011 and has extensive experience as an international economist including as Deputy Chief Economist in the Foreign and Commonwealth Office and working for Merrill Lynch’s fixed income business. Andrew has lived and worked as an economist in both Bulgaria and India. He has degrees in Economics and Economic History from Manchester University and the London School of Economics.",
      showText: false,
    },
    {
      image: "/images/speakers/fiona.jpg",
      imagex2: "/images/speakers/fiona@2x.jpg",
      position: "Founder Sapients Economics",
      name: "Fiona Mullen",
      text: "Fiona Mullen has been providing in-depth and independent economic and political analysis for well over 20 years. She founded Sapienta Economics Ltd in 2006 and is the author of the flagship monthly Sapienta Country Analysis Cyprus. Clients include international institutions, oil and gas majors, big four accounting firms, institutional investors, banks and more than a dozen embassies. Mullen is a registered holder of the Advanced Exam Certificate of CySEC, Cyprus’ financial regulator.",
      showText: false,
    },
    {
      image: "/images/speakers/gooch-peters-hannah.jpg",
      imagex2: "/images/speakers/gooch-peters-hannah@2x.jpg",
      position: "Global Equity Investment<br>Analyst, SANLAM",
      name: "Hannah Gooch-Peters",
      text: "Hannah joined Sanlam as global equity investment analyst in September 2015, providing insight and input into the research process and strategy discussions in a team covering both funds and discretionary segregated mandates totalling $1.5 billion across the Global High Quality strategy. She is a regular on Bloomberg TV, CNBC and Sky.<br>Hannah was awarded Investment Week’s ‘Young Investment Woman of the Year’ 2018.",
      showText: false,
    },
    {
      image: "/images/speakers/gregory-klumov.jpg",
      imagex2: "/images/speakers/gregory-klumov@2x.jpg",
      position: "CEO STASIS",
      name: "Gregory Klumov",
      text: "Gregory is an alternative asset management specialist with 20+ years of experience crafting complex multi-strategies for international markets and venture capital funding, an avid Decentralised Finance researcher, and an E-money 2.0 directive advocate.<br>As an expert in liquid and digital alternatives, as well as more traditional portfolio investments, Gregory now aims to shift stakeholders' mindsets to embrace cryptocurrency technologies.",
      showText: false,
    },
    {
      image: "/images/speakers/Kissack-new.jpg",
      imagex2: "/images/speakers/Kissack-new@2x.jpg",
      position: "Portfolio <br>Manager, Schroders",
      name: "Nick Kissack",
      text: "Nick Kissack is a UK and EU equity portfolio manager at Schroders. He joined Schroders in 2018 and is based in London. He was a Portfolio Manager at Janus Henderson from 2014 to 2018, involved in Pan-European long-only and long-short funds. Nick is a CFA Charterholder and has a BSc in Economics & Finance from the University of York.",
      showText: false,
    },
    {
      image: "/images/speakers/david.jpg",
      imagex2: "/images/speakers/david@2x.jpg",
      position: "financial <br>commentator",
      name: "David Buik MBE",
      text: "David Buik was appointed as a Member of the order of the British Empire in 2016 for his services to Financial Services. He is a businessman and financial pundit for the BBC and other British, American and Australian television channels and radio stations.He has appeared as a financial pundit on the BBC, Bloomberg Television, CNN International ABC News (Australia) and LBC Radio.",
      showText: false,
    },
    {
      image: "/images/speakers/Arnab.jpg",
      imagex2: "/images/speakers/Arnab@2x.jpg",
      position: "Head of Global <br>strategy EMEA, Invesco",
      name: "Arnab Das",
      text: "Arnab joined Invesco in 2015 as Head of EM macro research for Invesco Fixed Income. He began his career in finance in 1992 and has served as Co-head of Economic Research & Strategy at Roubini Global Economics; Co-head of Global Economics & Strategy, Head of Global FX Research, and Head of EM Economics & Strategy at Dresdner Kleinwort; and Head of EEMEA Research at JP Morgan.",
      showText: false,
    },
    {
      image: "/images/speakers/Bryn.jpg",
      imagex2: "/images/speakers/Bryn@2x.jpg",
      position: "head of fixed income, <br>Rathbones",
      name: "Bryn Jones",
      text: "Bryn is the head of fixed income for Rathbones and is lead manager on the Rathbone Ethical Bond Fund and the Rathbone Strategic Bond Fund. He joined Rathbones in November 2004 from Merrill Lynch, where he managed $2 billion of fixed income assets.",
      showText: false,
    },
    // {
    //   image: "/images/speakers/unknown-speaker.jpg",
    //   imagex2: "/images/speakers/unknown-speaker@2x.jpg",
    //   position: "Lorem ipsum<br>dolor sit amet",
    //   name: "Lorem ipsum",
    //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae ligula non turpis vulputate lacinia. Nullam augue ante, interdum ut volutpat eget, molestie quis mauris.",
    //   showText: false,
    //   label: "COMING SOON",
    //   blured: true,
    // },
  ],
  pastVideos: {
    videos: [
      {
        url: "822905582?h=7c634c553b",
        image: "/images/video/cover-all.jpg",
        title:
          "What’s on the Horizon for Global markets and how could it impact your portfolio?",
      },
      {
        url: "/pdf/HORIZON_Cyrpus_Report_2023.pdf",
        isPdf: true,
        image: "/images/video/cover-all.jpg",
        title: "Horizon Cyprus 2023. Summary",
      },
      {
        url: "825890540?h=af9a23f2e7",
        title: "Gregory Klumov",
        text: "CEO of STASIS",
        image: "/images/video/cover-gregory.jpg",
      },
      {
        url: "826917081?h=847c56afa1",
        title: "Arnab Das",
        text: "Head of Global Strategy",
        image: "/images/video/cover-arnab.jpg",
      },
      {
        url: "826917336?h=baae0e0745",
        title: "Hannah Gooch-Peters",
        text: "Global Equity",
        image: "/images/video/cover-hannah.jpg",
      },
      {
        url: "826917278?h=d63f4be354",
        title: "Fiona Mullen",
        text: "Director at Sapienta",
        image: "/images/video/cover-fiona.jpg",
      },
      {
        url: "801594536?h=0889fabdd0",
        title:
          "What’s on the horizon for stocks, bonds, commodities &&nbsp;alternative assets in 2023? Full&nbsp;video.",
        image: "/images/video/full-video.jpg",
      },
      {
        url: "796575690?h=cd46803b9d",
        title: "Arnab Das",
        text: "Head of global strategy EMEA at Invesco",
        image: "/images/video/DSCF9294.jpg",
      },
      {
        url: "796575656?h=0c207d6a7a",
        title: "Bryn Jones",
        text: "Head of fixed income at Rathbones",
        image: "/images/video/DSCF9287.jpg",
      },
      {
        url: "802587093?h=3546d0f97b",
        title: "David Buik",
        text: "Financial commentator, MBE",
        image: "/images/video/DSCF9325.jpg",
      },
      {
        url: "796575592?h=cde5a538b3",
        title: "Nick Kissack",
        text: "Portfolio manager at Schroders",
        image: "/images/video/DSCF9333.jpg",
      },
    ],
  },
}

export default DATA
